<template>
  <div>
    <div v-if="isLoading" class="loading-symbol"></div>

    <ResponseComponent
      :responseMessage="responseMessage"
      :responseError="responseError"
      :tableComponentExists="tableComponentExists"
      :tableData="tableData"
    />

    <!-- Only display this section if there's no responseMessage -->
    <div v-if="!responseMessage">
      <h1 style="font-size: 36px; font-weight: bold;">On-Board a New Facility</h1>
      <div style="height: 12px;"></div>

      <div>
        <label for="organization">Organization</label>
        <input
          id="organization"
          type="text"
          v-model="organization"
          placeholder="Enter the organization name"
        />
      </div>

      <div style="margin-top: 10px;">
        <label for="recipient">Recipient</label>
        <input
          id="recipient"
          type="text"
          v-model="recipient"
          placeholder="Enter the recipient's name"
        />
      </div>

      <div>
        <label for="street-line">Street Line</label>
        <input
          id="street-line"
          type="text"
          placeholder="Enter your street"
          ref="streetLineInput"
        />
      </div>

      <div style="margin-top: 20px;">
        <label>City: </label>
        <input v-model="city" />
      </div>

      <div style="margin-top: 10px;">
        <label>State: </label>
        <input v-model="state" />
      </div>

      <div style="margin-top: 10px;">
        <label>Country: </label>
        <input v-model="country" />
      </div>

      <div style="margin-top: 10px;">
        <label>Zipcode: </label>
        <input v-model="zipcode" />
      </div>

      <button @click="submitAddress">Submit Address</button>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      organization: '',
      recipient: '',
      streetLine: '', // This will store the formatted street line
      city: '',
      state: '',
      country: '',
      zipcode: '',
      responseMessage: '',
      responseError: '',
      isLoading: false,
      tableData: {
        items: [],
        columns: []
      },
      tableComponentExists: false,
      googleApiLoaded: false // To track whether the API has been loaded
    };
  },
  mounted() {
    this.loadGoogleMapsApi();
    document.title = 'New Facility';
  },
  methods: {
    async handleFormSubmission(apiUrl, formData) {
      const formPayload = new FormData();
      for (const [key, value] of Object.entries(formData)) {
        formPayload.append(key, value);
      }

      try {
        const response = await axios.post(apiUrl, formPayload, {
          headers: {
            'Authorization': `Token ${localStorage.getItem('authToken')}`
            }});
        if (response.data.message) {
          this.responseMessage = response.data.message;
        }
        if (response.data.error) {
          this.responseError = response.data.error;
        }
        if (response.data.file) {
          this.downloadCSV(response.data.file.csv, response.data.file.name);
        }
        if (response.data.component === 'table') {
          this.tableData.items = response.data.items;
          this.tableData.columns = response.data.columns;
          this.tableComponentExists = true;
        }
      } catch (error) {
        console.error('Upload failed:', error);
        this.responseError = 'An error occurred during the upload.';
      }
    },

    initializeAutocomplete() {
      // Initialize Google Places autocomplete on the street line input
      const autocomplete = new google.maps.places.Autocomplete(this.$refs.streetLineInput, {
        types: ['address'], // Only show address predictions
      });

      // Listen for the place_changed event
      autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace();
        if (place && place.address_components) {
          this.extractAddressComponents(place.address_components);
        }
      });
    },

    extractAddressComponents(addressComponents) {
      let streetNumber = '';
      let route = '';

      // Extract and map Google Places address components to form fields
      addressComponents.forEach(component => {
        const componentType = component.types[0];
        switch (componentType) {
          case 'street_number':
            streetNumber = component.long_name;
            break;
          case 'route':
            route = component.long_name;
            break;
          case 'locality':
            this.city = component.long_name;
            break;
          case 'administrative_area_level_1':
            this.state = component.short_name;
            break;
          case 'country':
            this.country = component.long_name;
            break;
          case 'postal_code':
            this.zipcode = component.long_name;
            break;
          default:
            break;
        }
      });

      // Set the street line as street number + route
      this.streetLine = `${streetNumber} ${route}`.trim();

      // Update the street line input field directly
      this.$refs.streetLineInput.value = this.streetLine;
    },

    loadGoogleMapsApi() {
      // Check if Google Maps API is already loaded
      if (this.googleApiLoaded) return;

      // Create a script element and set the src to Google Maps API URL
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyBMWdrNaa4f9gDWR-rhlRVApJndy-4OUcQ&libraries=places`;
      script.async = true;
      script.defer = true;

      // Append the script to the body
      document.body.appendChild(script);

      // Set the googleApiLoaded flag to true when the script is successfully loaded
      script.onload = () => {
        this.googleApiLoaded = true;
        this.initializeAutocomplete(); // Initialize autocomplete after the script is loaded
      };
    },

    submitAddress() {
      const formData = {
        organization: this.organization,
        recipient: this.recipient,
        streetLine: this.streetLine,
        city: this.city,
        state: this.state,
        country: this.country,
        zipcode: this.zipcode,
        action: 'NewFacility',
      };

      this.handleFormSubmission(`${process.env.VUE_APP_WETLAB_URL}/fulfillment/newfacility/`, formData);
    }
  },
};
</script>

<style scoped>
input {
  display: block;
  margin-bottom: 10px;
  padding: 8px;
  width: 300px;
}
button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
}
button:hover {
  background-color: #0056b3;
}
</style>

<template>
    <div id="wrapper">
        <nav class="navbar is-dark">
            <div class="navbar-brand">
                <a class="navbar-burger" style="color: black; background-color: #18B6E9" aria-label="menu" aria-expanded="false" data-target="navbar-menu" @click="showMobileMenu = !showMobileMenu">
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                </a>
            </div>

            <div class="navbar-menu" id="navbar-menu" v-bind:class="{'is-active': showMobileMenu }" style="background-color: #F9FAFB">
                <div class="navbar-start">
                    <div class="navbar-item">
                        <router-link to="/wetlab" class="button" style="margin-right: 15px;">
                            <img src="@/assets/curvebio_logo.png" alt="Curve Bio Logo" />
                        </router-link>
                        <router-link to="/wetlab/fulfillment" class="button" style="margin-right: 15px;">Kit Fulfillment</router-link>
                        <router-link to="/wetlab/accession" class="button" style="margin-right: 15px;">Accessioning</router-link>
                        <router-link to="/wetlab/preamp" class="button" style="margin-right: 15px;">PreAmp</router-link>
                        <router-link to="/wetlab/postamp" class="button" style="margin-right: 15px;">PostAmp</router-link>
                    </div>
                </div>

                <div class="navbar-end">
                    <div class="navbar-item">
                        <form method="get" action="/wetlab/search">
                            <div class="field has-addons">
                                <div class="control">
                                    <input type="text" class="input" placeholder="Search" name="query">
                                </div>
                                <div class="control">
                                    <button class="button" style="background-color: #18B6E9">
                                        <span class="icon">
                                            <i class="fas fa-search"></i>
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </form>

                        <router-link 
                            to="/wetlab/login" 
                            class="button" 
                            style="margin-left: 15px; background-color: #18B6E9">
                            {{ isLoggedIn ? 'Logout' : 'Login' }}
                        </router-link>
                    </div>
                </div>
            </div>
        </nav>

        <div class="layout-container">
            <!-- Left column for dynamic links based on the current route -->
            <div v-if="isFulfillment || isAccession || isPreamp || isObject || isPostamp" class="layout-container-left">
                <div class="layout-container-left">
                    <div v-if="isFulfillment">
                        <p class="left-list-header">Fulfillment Reports</p>
                        <router-link to="/wetlab/fulfillment/facilitylist" class="left-list">Facility List</router-link>
                        <router-link to="/wetlab/fulfillment/kitlist" class="left-list">Kit List</router-link>
                        <router-link to="/wetlab/fulfillment/shipmentlist" class="left-list">Shipment List</router-link>

                        <p class="left-list-header">Fulfillment Actions</p>
                        <router-link to="/wetlab/fulfillment/initiatekits" class="left-list">Initiate Kits</router-link>
                        <router-link to="/wetlab/fulfillment/assemblekits" class="left-list">Assemble Kits</router-link>
                        <router-link to="/wetlab/fulfillment/newfacility" class="left-list">On-Board New Facility</router-link>
                        <router-link to="/wetlab/fulfillment/mailkits" class="left-list">Ship Kits</router-link>
                    </div>
                    <div v-if="isAccession">
                        <p class="left-list-header">Accession Reports</p>
                        <router-link to="/wetlab/accession/crc" class="left-list">CRC Patients</router-link>
                        <p class="left-list-header">Accession Actions</p>
                        <router-link to="/wetlab/accession/accessionrack" class="left-list">Accession Rack from Collection Site</router-link>
                    </div>
                    <div v-if="isPreamp">
                        <p class="left-list-header">PreAmp Actions</p>
                        <router-link to="/wetlab/preamp/kickoff" class="left-list">PreAmpBatch Kickoff</router-link>
                    </div>
                    <div v-if="isPostamp">
                        <p class="left-list-header">PostAmp Actions</p>
                    </div>
                    <div v-if="isObject">
                        <p class="left-list-header">Object Reports</p>
                        <router-link to="/wetlab/fulfillment/facilitylist" class="left-list">Facility List</router-link>
                        <router-link to="/wetlab/fulfillment/kitlist" class="left-list">Kit List</router-link>
                        <router-link to="/wetlab/fulfillment/shipmentlist" class="left-list">Shipment List</router-link>

                        <p class="left-list-header">Object Actions</p>

                    </div>
                </div>
            </div> 

            <!-- Main content -->
            <section class="section right-column">
                <router-view/>
            </section>
        </div>

        <footer class="footer">
            <p class="has-text-centered">Keep calm and execute well</p>
        </footer>
    </div>
</template>

<script>
export default {
    name: 'Wetlab',

    data() {
        return {
            showMobileMenu: false,
        }
    },
    computed: {
        isLoggedIn() {
            return !!localStorage.getItem('authToken');
        },
        // Determine if the user is on the /wetlab/fulfillment route
        isFulfillment() {
            return this.$route.path.startsWith('/wetlab/fulfillment');
        },
        // Determine if the user is on the /wetlab/accession route
        isAccession() {
            return this.$route.path.startsWith('/wetlab/accession');
        },
        // Determine if the user is on the /wetlab/preamp route
        isPreamp() {
            return this.$route.path.startsWith('/wetlab/preamp');
        },
        isPostamp() {
            return this.$route.path.startsWith('/wetlab/postamp');
        },
        isObject() {
            return this.$route.path.startsWith('/wetlab/models');
        },
    }
}
</script>


<style lang="scss">
@import '../../node_modules/bulma';

// Navbar specific settings

.footer {
}
.navbar {
}
.navbar-item {
    height: auto;
}
.navbar-item .button {
    height: 40px;
    display: flex;
    align-items: center; /* Vertically center content */
}

// Left side container thing

.layout-container {
    border: 1px solid;
    border-color: rgb(209, 212, 220);
    max-width: none;
    display: flex;
    width: 100%; /* Ensure the container uses the full width of the page */
}
.layout-container-left {
    padding-top: 30px;
    padding-bottom: 30px;
    width: 225px;
    background-color: rgb(248, 249, 250); /* background color */
} 

.left-list {
    display: block;
    padding-left: 25px;
    padding-right: 5px;
    padding-bottom: 10px;
    color: #18B6E9; /* Set hyperlink color to curve blue */
    text-decoration: none; /* Optional, remove for no underline */
}

.left-list-header {
    display: block;
    font-weight: bold;
    padding-left: 10px;
    padding-bottom: 10px;
}

// Default button settings
button {
    font-weight: bold;
    border-radius: 5px;
    padding: 10px 20px;     /* padding around text area aka make button larger */
    background-color: #18B6E9; /* background color */
    cursor: pointer;        /* Pointer cursor on hover */
}
button:hover {
    background-color: lightgreen; /* Optional: change background color on hover */
}
button:disabled {
    cursor: not-allowed;
}

// Color that clickable links should appear
.curve-link {
    color: #18B6E9; /* Set hyperlink color to curve blue */
    text-decoration: none; /* Optional, remove for no underline */
}

// Loading settings
.loading-symbol {
    width: 60px;
    aspect-ratio: 1;
    display: flex;
    color: #000;
    border: 4px solid;
    box-sizing: border-box;
    border-radius: 50%;
    background: 
        radial-gradient(circle 5px, currentColor 95%,#0000),
        linear-gradient(currentColor 50%,#0000 0) 50%/4px 60% no-repeat;
    animation: l1 2s infinite linear;
}

.loading-symbol:before {
    content: "";
    flex: 1;
    background: linear-gradient(currentColor 50%, #0000 0) 50%/4px 80% no-repeat;
    animation: inherit;
}
@keyframes l1 {
    100% { transform: rotate(1turn); }
}

// Settings for column displays & svg mostly for model pages
// Note: A Vue can contain a left-column AND (a right-column OR svg-container)
.container {

    display: flex;
    margin: 0;
    width: 100%; /* Ensure the container uses the full width of the page */
}
.left-column {
    width: 40%;
} 
.right-column {
    width: 100%;
    padding: 50px;
}
.svg-container {
    width: 60%;
    padding: 100px;
    overflow-y: auto;  /* Allows scrolling if content overflows */
}
.scaled-svg {
    transform: scale(0.60);
    transform-origin: top left;  /* Ensure the scaling starts from the top left */
}
</style>

<template>
  <div>
    <div v-if="isLoading" class="loading-symbol"></div>

    <h1 class="preserve-whitespace" style="font-size: 36px; font-weight: bold;">Kit Fulfillment Home</h1>

    <div style="width: 640px; height: 480px; margin: 10px; position: relative;"><iframe allowfullscreen frameborder="0" style="width:640px; height:480px" src="https://lucid.app/documents/embedded/e6259c66-a584-4346-8b12-dd027cc97a16" id="Wy4d0EonZibw"></iframe></div>
    
    <ResponseComponent
      :responseMessage="responseMessage"
      :responseError="responseError"
      :tableComponentExists="tableComponentExists"
      :tableData="tableData"
    />

    <component 
      v-if="!responseMessage" 
      v-for="block in pageElements" 
      :key="block.index" 
      :is="block.component" 
      :block="block" 
      @formSubmitted="submitForm"
    ></component>
  </div>

</template>

<script>
import ActionMixin from '@/mixins/ActionMixin.js'

export default {
  mixins: [ActionMixin],
  mounted() {
    document.title = `Fulfillment Home`;
  },
  created() {
    this.fetchPageStructure(`${process.env.VUE_APP_WETLAB_URL}/fulfillment/`);
  },
  methods: {
    submitForm(formData) {
      this.handleFormSubmission(`${process.env.VUE_APP_WETLAB_URL}/fulfillment/`, formData);
    },
  },
  computed: {
    // Additional logic to determine whether to render pageElements or not
    showPageElements() {
      return !this.responseMessage; // Page elements are hidden when a responseMessage exists
    }
  },
};
</script>

<style>
.overview {
  max-width: 750px;
  height: auto;
}
</style>

<template>
  <div>
    <div v-if="isLoading" class="loading-symbol"></div>

    <h1 class="preserve-whitespace" style="font-size: 36px; font-weight: bold;">Sample Batching Home</h1>

    <div style="width: 640px; height: 480px; margin: 10px; position: relative;"><iframe allowfullscreen frameborder="0" style="width:640px; height:480px" src="https://lucid.app/documents/embedded/c0133b81-b09b-4819-aa6d-30a1be0a5299" id="BH4dqNrbpL5N"></iframe></div>

    <ResponseComponent
      :responseMessage="responseMessage"
      :responseError="responseError"
      :tableComponentExists="tableComponentExists"
      :tableData="tableData"
    />
    
    <component 
      v-for="block in pageElements" 
      :key="block.index" 
      :is="block.component" 
      :block="block" 
      @formSubmitted="submitForm"
    ></component>
  </div>
</template>

<script>
import ActionMixin from '@/mixins/ActionMixin.js'

export default {
  mixins: [ActionMixin],
  mounted() {
    document.title = `Sample Batching Home`;
  },
  created() {
    this.fetchPageStructure(`${process.env.VUE_APP_WETLAB_URL}/samplebatching/`);
  },
  methods: {
    submitForm(formData) {
      this.handleFormSubmission(`${process.env.VUE_APP_WETLAB_URL}/samplebatching/`, formData);
    },
  },
};
</script>


<template>
  <div>
    <div v-if="isLoading" class="loading-symbol"></div>

    <h1 class="preserve-whitespace" style="font-size: 36px; font-weight: bold;">Accession Home</h1>

    <div style="width: 640px; height: 480px; margin: 10px; position: relative;"><iframe allowfullscreen frameborder="0" style="width:640px; height:480px" src="https://lucid.app/documents/embedded/935d7a9c-27ad-4504-b695-ac61643e1a38" id="abFerLOKl608"></iframe></div>

    <ResponseComponent
      :responseMessage="responseMessage"
      :responseError="responseError"
      :tableComponentExists="tableComponentExists"
      :tableData="tableData"
    />
    
    <component 
      v-for="block in pageElements" 
      :key="block.index" 
      :is="block.component" 
      :block="block" 
      @formSubmitted="submitForm"
    ></component>
  </div>
</template>

<script>
import ActionMixin from '@/mixins/ActionMixin.js'

export default {
  mixins: [ActionMixin],
  mounted() {
    document.title = `Accession Home`;
  },
  created() {
    this.fetchPageStructure(`${process.env.VUE_APP_WETLAB_URL}/accession/`);
  },
  methods: {
    submitForm(formData) {
      this.handleFormSubmission(`${process.env.VUE_APP_WETLAB_URL}/accession/`, formData);
    },
  },
};
</script>


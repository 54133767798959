<template>
  <div>
    <template v-for="(item, index) in block.items" :key="index">
      <!-- Render different input types based on 'type' -->
      <div v-if="item.type === 'text-box'">
        <input type="text" v-model="formData[item.variable_name]" :placeholder="item.title">
        <div style="height: 12px;"></div>
      </div>
      <div v-else-if="item.type === 'large-text-box'">
        <textarea v-model="formData[item.variable_name]" :placeholder="item.title" rows="5" cols="40"></textarea>
        <div style="height: 12px;"></div>
      </div>
      <div v-else-if="item.type === 'checkbox'">
        <input type="checkbox" v-model="formData[item.variable_name]"style="transform: scale(1.2);">
        <span style="margin-left: 8px;font-size: 12px;">{{ item.title }}</span>
      </div>
      <label v-else-if="item.type === 'dropdown'">
        {{ item.title }}:
        <select v-model="formData[item.variable_name]">
          <option v-for="option in item.options" :key="option.value" :value="option.value">{{ option.label }}</option>
        </select>
        <div style="height: 12px;"></div>
      </label>
      <!-- Add Upload File functionality -->
      <div v-else-if="item.type === 'uploadfile'">
        <h1>{{ item.title }}</h1>
        <input type="file" :ref="'fileInput' + index" @change="handleFileSelection(item.variable_name, index)" accept=".csv, .xls, .xlsx" />
        <div style="height: 12px;"></div>
      </div>
      <div v-else-if="item.type === 'selectedtable'">
        <SelectedTable ref="selectedTable" :block="item.items" />
      </div>
    </template>
    <button @click="submitForm">Submit</button>
  </div>
</template>

<script>
import SelectedTable from './SelectedTable.vue';

export default {
  components: {
    SelectedTable // Register SelectedTable component
  },
  props: {
    block: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      formData: {},  // Object to store form data dynamically
      selectedFiles: {}  // Object to store selected files for uploadcsv type
    };
  },
  methods: {
    handleFileSelection(variable_name, index) {
      const input = this.$refs['fileInput' + index][0];
      if (input.files && input.files[0]) {
        this.selectedFiles[variable_name] = input.files[0];
      } else {
        this.selectedFiles[variable_name] = null;
      }
    },

    submitForm() {

    // First deal with retreiving the items selected in the table if there was a table
    const selectedTableRef = this.$refs.selectedTable;
    // Check if the ref is an array and access the first element if it is
    const selectedTable = Array.isArray(selectedTableRef) ? selectedTableRef[0] : selectedTableRef;
    if (selectedTable && typeof selectedTable.getSelectedRows === 'function') {
      const selectedRows = selectedTable.getSelectedRows();
      this.formData['selected_rows'] = JSON.stringify(selectedRows);
    } 

    // Return the form data
    const combinedData = { ...this.formData, ...this.selectedFiles};
    combinedData['action'] = this.block.action;
    this.$emit('formSubmitted', combinedData);
    }
  }
};
</script>

<style scoped>
h1 {
  font-size: 16px;
  margin-bottom: 16px;
  margin-top:16px;
}

input[type="file"] {
  display: block;
  margin-top: 16px;
}
</style>
